<template>
  <v-main style="background-color: #fff" class="mt-16">
    <v-container class="mt-16">
      <v-img src="/bg2.jpg"></v-img>
      <h1 class="mt-16 text-h2">Миний аавын нутаг {{place.name}}</h1>
      <p class="mt-4">
        Persis Drell is Stanford's thirteenth provost. As the chief academic and
        chief budgetary officer of the university, the provost is responsible
        for administering the academic program, including both instruction and
        research, and for
      </p>
    </v-container>

    <section class="mt-10">
      <v-container>
        <v-row>
          <v-col cols="12" md="9" lg="9" sm="12">
            <div class="text-h2">WHAT CAN I EXPECT FROM AUSTRALIA?</div>
            <p>
              Per consequat adolescens ex, cu nibh commune temporibus vim, ad
              sumo viris eloquentiam sed. Mea appareat omittantur eloquentiam
              ad, nam ei quas oportere democritum. Prima causae admodum id est,
              ei timeam inimicus sed. Sit an meis aliquam, cetero inermis vel
              ut. An sit illum euismod facilisis, tamquam vulputate pertinacia
              eum at.
            </p>
            <p class="mt-5">
              Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin,
              lorem quis bibendum auctor, nisi elit consequat ipsum, nec
              sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate
              cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec
              tellus a odio tincidunt auctor a ornare odio. Sed non mauris vitae
              erat consequat auctor eu in elit.
            </p>
            <h5
              style="
                letter-spacing: 0.2em;
                text-transform: uppercase;
                font-family: Montserrat, sans-serif;
                font-weight: lighter;
              "
              class="my-10"
            >
              THINGS TO SEE AND DO IN AUSTRALIA
            </h5>

            <v-row>
              <v-col cols="6" class="ml-3">
                <v-row>
                  <v-icon x-small color="#f7775e" class="mr-2"
                    >mdi-circle-slice-8</v-icon
                  >
                  <p>Dolorem mediocritatem</p>
                </v-row>
                <v-row>
                  <v-icon x-small color="#f7775e" class="mr-2"
                    >mdi-circle-slice-8</v-icon
                  >
                  <p>Mea appareat</p>
                </v-row>
                <v-row>
                  <v-icon x-small color="#f7775e" class="mr-2"
                    >mdi-circle-slice-8</v-icon
                  >
                  <p>Prima causae</p>
                </v-row>
                <v-row>
                  <v-icon x-small color="#f7775e" class="mr-2"
                    >mdi-circle-slice-8</v-icon
                  >
                  <p>Singulis indoctum</p>
                </v-row>
              </v-col>
              <v-col cols="5">
                <v-row>
                  <v-icon x-small color="#f7775e" class="mr-2"
                    >mdi-circle-slice-8</v-icon
                  >
                  <p>Timeam inimicus</p>
                </v-row>
                <v-row>
                  <v-icon x-small color="#f7775e" class="mr-2"
                    >mdi-circle-slice-8</v-icon
                  >
                  <p>Oportere democritum</p>
                </v-row>
                <v-row>
                  <v-icon x-small color="#f7775e" class="mr-2"
                    >mdi-circle-slice-8</v-icon
                  >
                  <p>Cetero inermis</p>
                </v-row>
                <v-row>
                  <v-icon x-small color="#f7775e" class="mr-2"
                    >mdi-circle-slice-8</v-icon
                  >
                  <p>Pertinacia eum</p>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="my-16">
              <v-col cols="12" lg="6" md="6" sm="6">
                <v-img src="/img2.jpg"></v-img>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="6">
                <v-img src="/detail1.jpg"></v-img>
              </v-col>
            </v-row>

            <div class="my-10 text-h2">TYPICAL COSTS WHEN TRAVELLING</div>
            <p>
              <strong>Accommodation</strong> – Quo at mollis tritani molestie,
              munere vivendum sit ei, nec congue bonorum an. In quidam iriure
              alienum nam, in scripta probatus usu. Aperiam tractatos ex his, ex
              usu facete accumsan, duo platonem efficiantur intellegebat ut.
              Accusata scripserit persequeris pri eu, accusam argumentum
              disputationi an his. Nulla erant urbanitas sed ne, mei velit
              laudem id, nonumy eligendi pri ei. Eu mel everti ocurreret, nemore
              quodsi referrentur ius ad.
            </p>
            <p>
              <strong>Food</strong> – Quo at mollis tritani molestie, munere
              vivendum sit ei, nec congue bonorum an. In quidam iriure alienum
              nam, in scripta probatus usu. Aperiam tractatos ex his, ex usu
              facete accumsan, duo platonem efficiantur intellegebat ut.
            </p>
            <p>
              <strong>Transportation</strong> – Accusata vulputate pri an, mel
              no vivendo deleniti, aliquid probatus elaboraret ut quo. Veniam
              facete intellegebat et mei, cetero luptatum definiebas at nec.
              Nisl viris inimicus no eam. Audire platonem id mea, prompta
              volumus mandamus mel cu. Pri in wisi ancillae constituam, nec nisl
              numquam honestatis eu. Quo at mollis tritani molestie, munere
              vivendum sit ei, nec congue bonorum an. In quidam iriure alienum
              nam, in scripta probatus usu. In wisi adhuc oportere quo, eu
              fabulas principes his. Esse justo euripidis duo ea. Eum ad audire
              dolorum, no nullam dicunt bonorum.
            </p>
            <v-img src="/detail2.png"></v-img>
            <h5
              style="
                letter-spacing: 0.2em;
                text-transform: uppercase;
                font-family: Montserrat, sans-serif;
                font-weight: lighter;
              "
              class="my-10"
            >
              MONEY SAVING TIPS
            </h5>
            <v-row>
              <v-col cols="12" md="6" lg="6" sm="12">
                <v-col cols="12">
                  <span
                    style="
                      color: #f2756a;
                      font-family: Lora, serif;
                      font-size: 20px;
                      font-weight: 700;
                      display: table-cell;
                    "
                    class="pr-3"
                    >1.</span
                  >
                  <p style="display: table-cell">
                    <strong>Get the menu of the day -</strong>Accusata vulputate
                    pri an, mel no vivendo deleniti, aliquid probatus elaboraret
                    ut quo. Veniam facete intellegebat et mei, cetero luptatum
                    definiebas at nec. Nisl viris.
                  </p>
                </v-col>
                <v-col cols="12">
                  <span
                    style="
                      color: #f2756a;
                      font-family: Lora, serif;
                      font-size: 20px;
                      font-weight: 700;
                      display: table-cell;
                    "
                    class="pr-3"
                    >2.</span
                  >
                  <p style="display: table-cell">
                    <strong>Ride with Uber -</strong>Quo at mollis tritani
                    molestie, munere vivendum sit ei, nec congue bonorum an. In
                    quidam iriure alienum nam, in scripta probatus usu. Aperiam
                    ex his, ex usu.
                  </p>
                </v-col>
              </v-col>
              <v-col cols="12" md="6" lg="6" sm="12">
                <v-col cols="12">
                  <span
                    style="
                      color: #f2756a;
                      font-family: Lora, serif;
                      font-size: 20px;
                      font-weight: 700;
                      display: table-cell;
                    "
                    class="pr-3"
                    >3.</span
                  >
                  <p style="display: table-cell">
                    <strong>Get city passes -</strong>Cetero luptatum definiebas
                    at nec. Nisl viris inimicus no eam. Audire platonem id mea,
                    prompta volumus mandamus cu. Pri in wisi ancillae, nec nisl
                    numquam eu dicunt bonorum.
                  </p>
                </v-col>
                <v-col cols="12">
                  <span
                    style="
                      color: #f2756a;
                      font-family: Lora, serif;
                      font-size: 20px;
                      font-weight: 700;
                      display: table-cell;
                    "
                    class="pr-3"
                    >4.</span
                  >
                  <p style="display: table-cell">
                    <strong>Couchsurf -</strong>Scripta probatus usu. Aperiam
                    tractatos ex his, ex usu facete accumsan, duo platonem
                    efficiantur intellegebat ut. Accusata scripserit pri eu,
                    accusam no nullam.
                  </p>
                </v-col>
              </v-col>
            </v-row>
            <v-row class="my-10 py-10 px-5" style="background-color: #f7f2ee">
              <v-col cols="12" class="text-center mb-5">
                <div class="mb-3 text-h2">
                  MY MUST HAVE GUIDES FOR TRAVELING
                </div>
                <span class="text-h4"
                  >Everything you need to know about traveling.</span
                >
              </v-col>

              <v-col cols="12" md="3" lg="3" sm="6"
                ><v-img src="/book1.png"></v-img
              ></v-col>
              <v-col cols="12" md="3" lg="3" sm="6"
                ><v-img src="/book2.png"></v-img
              ></v-col>
              <v-col cols="12" md="3" lg="3" sm="6"
                ><v-img src="/book3.png"></v-img
              ></v-col>
              <v-col cols="12" md="3" lg="3" sm="6"
                ><v-img src="/book4.png"></v-img
              ></v-col>
            </v-row>
            <div class="mb-7 text-h2">WHAT CAN I EXPECT FROM AUSTRALIA?</div>
            <p>
              Lorem ipsum proin gravida nibh vel velit auctor aliquet. Aenean
              sollicitudin, lorem quis bibendum auctor, nisi elit consequat
              ipsum, nec sagittis sem nibh id elit.Duis sed odio sit amet nibh
              vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit.
              Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non
              mauris vitae erat consequat auctor eu in elit. Class aptent taciti
              sociosqu ad litora torquent per conubia nostra, per inceptos
              himenaeos. Mauris in erat justo. Nullam ac urna eu felis dapibus
              condimentum sit amet a augue consequat elis. Proin gravida nibh
              vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum
              auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit.
              Duis sed odio sit amet nibh vulputate cursus a sit amet mauris.
              Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt.
            </p>
            <v-row class="my-10">
              <v-col cols="12" sm="12" md="4" lg="4" class="text-center"
                ><v-img src="/cover1.jpg" class="mb-2"></v-img>
                <div class="text-h4">Сайн байна уу?</div></v-col
              >
              <v-col cols="12" sm="12" md="4" lg="4" class="text-center"
                ><v-img src="/cover2.jpg" class="mb-2"></v-img>
                <div class="text-h4">
                  MEET THE <br />
                  PHOTOGRAPHER WHO STARS
                </div></v-col
              >
              <v-col cols="12" sm="12" md="4" lg="4" class="text-center"
                ><v-img src="/cover3.jpg" class="mb-2"></v-img>
                <div class="text-h4">
                  TAKE ME TO PARIS,<br />
                  NOW! 😍
                </div></v-col
              >
            </v-row>
            <p>
              Засгийн газрын хуралдаанаар улбар шар түвшинд зарим салбарын үйл
              ажиллагааг хязгаарлах шийдвэр гаргасан. Нийтдээ 14 хоногийн
              хугацаатай арга хэмжээг авч, зохион байгуулна. Засгийн газраас
              иргэдийн эрүүл мэндийг хамгаалж, эдийн засгаа унагаахгүй байх
              боломжит тооцооллыг хийж, аль болох уян хатан арга хэмжээг авч
              хэрэгжүүлэхээр ажиллаж байна. Төрийн байгууллага төдийгүй хувийн
              хэвшлийн аж ахуй нэгжүүд 30-аас дээшгүй хувиар биечлэн ажиллах
              шаардлагатай. Бид аль болох шууд хатуу хөл хорио тогтоохгүйгээр
              асуудлыг шийдвэрлэнэ. Тавдугаар сарын 1-ний дотор Улаанбаатар
              хотын насанд хүрсэн амынхаа вакцинжуулалтын эхний тунг хийж
              дуусгана. Долоодугаар сарын 1-н хүртэл орон нутгийн иргэд гээд бүх
              салбарт вакцинжуулалтын ажлаа хүргэнэ. Энэ хугацаанд зам дэд
              бүтэц, бусад салбарын бүтээн байгуулалтын ажил зогсохгүй учраас
              халдвар хамгааллын дэглэмийг баримталж ажиллах ёстой” гэв.
            </p>
          </v-col>
          <v-col cols="12" md="3" lg="3" sm="10" class="mx-auto">
            <v-card class="py-10 mb-5" align="center" outlined flat>
              <v-img
                src="/map2.png"
                :aspect-ratio="1 / 1"
                contain
                class="mx-10"
              ></v-img>
              <span
                class="text-h5"
                style="
                  display: block;
                  margin-bottom: 2px;
                  font-family: EpicRide, serif;
                  font-weight: 400;
                  letter-spacing: 0;
                  font-size: 44px;
                  line-height: 1em;
                "
                >Australia</span
              >
              <span
                style="
                  display: block;
                  margin-bottom: 22px;
                  font-family: Montserrat, sans-serif;
                  font-weight: 400;
                  text-transform: uppercase;
                  font-size: 10px;
                  letter-spacing: 0.33em;
                "
                >4 RELATED ARTICLES</span
              >
              <v-btn outlined tile @click="_goDetail">See articles</v-btn>
            </v-card>
            <v-card outlined flat class="">
              <v-btn
                block
                depressed
                class="btn text-button"
                style="font-weight: 300; background-color: transparent"
                ><v-icon class="mr-4">mdi-eye</v-icon>Things to see</v-btn
              >
              <v-btn
                block
                depressed
                class="btn text-button"
                style="font-weight: 300; background-color: transparent"
                ><v-icon class="mr-4">mdi-currency-usd</v-icon>Typical
                Costs</v-btn
              >
              <v-btn
                block
                depressed
                class="btn text-button"
                style="font-weight: 300; background-color: transparent"
                ><v-icon class="mr-4">mdi-wallet-outline</v-icon>Budget
                Tips</v-btn
              >
              <v-btn
                block
                depressed
                class="btn text-button"
                style="font-weight: 300; background-color: transparent"
                ><v-icon class="mr-4">mdi-bookmark-multiple-outline</v-icon
                >Related Guides</v-btn
              >
              <v-btn
                block
                depressed
                class="btn text-button"
                style="font-weight: 300; background-color: transparent"
                ><v-icon class="mr-4">mdi-camera</v-icon>Related Articles</v-btn
              >
              <v-btn
                disabled
                block
                depressed
                class="btn text-button"
                style="
                  font-weight: 300;
                  background-color: white !important;
                  color: black !important;
                "
                ><v-icon class="mr-4" style="color: black !important"
                  >mdi-comment-text-multiple-outline</v-icon
                >Comments</v-btn
              >
            </v-card>
             <v-card outlined flat class="mt-16 stick">
        <v-img
          height="200px"
          src="https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg"
        >
          <v-app-bar
            flat
            color="rgba(0, 0, 0, 0)"
          >
            <v-app-bar-nav-icon color="white"></v-app-bar-nav-icon>

            <v-toolbar-title class="title white--text pl-0">
              Messages
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
              color="white"
              icon
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-app-bar>

          <v-card-title class="white--text mt-8">
            <v-avatar size="56">
              <img
                alt="user"
                src="https://cdn.pixabay.com/photo/2020/06/24/19/12/cabbage-5337431_1280.jpg"
              >
            </v-avatar>
            <p class="ml-3">
              John Doe
            </p>
          </v-card-title>
        </v-img>

        <v-card-text>
          <div class="font-weight-bold ml-8 mb-2">
            Today
          </div>

          <v-timeline
            align-top
            dense
          >
            <v-timeline-item
              v-for="message in messages"
              :key="message.time"
              :color="message.color"
              small
            >
              <div>
                <div class="font-weight-normal">
                  <strong>{{ message.from }}</strong> @{{ message.time }}
                </div>
                <div>{{ message.message }}</div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </v-main>
</template>

<style>
.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}
.btn {
  justify-content: start;
}
@media only screen and (min-width: 1100px) {
  .navbtn {
    visibility: hidden;
  }
}
@media only screen and (max-width: 1100px) {
  .navmenu {
    display: none;
  }
}
.stick {
  position: sticky;
  top: 10%;
}
</style>
<script>
const fb = require("../../firebaseConfig.js");
export default {
  name: "TourDetail",
  components: {},
  data: () => ({
    place:null,
    messages: [
        {
          from: 'You',
          message: `Sure, I'll see you later.`,
          time: '10:42am',
          color: 'deep-purple lighten-1',
        },
        {
          from: 'John Doe',
          message: 'Yeah, sure. Does 1:00pm work?',
          time: '10:37am',
          color: 'green',
        },
        {
          from: 'You',
          message: 'Did you still want to grab lunch today?',
          time: '9:47am',
          color: 'deep-purple lighten-1',
        },
      ],
    drawer: false,
    group: null,
    items: ["Home", "Pricing", "About Us"],
    items1: [
      "News",
      "Events",
      "Academics",
      "Research",
      "Health Care",
      "Campus Life",
      "Admission",
      "About",
    ],
  }),
  props: {
    itemId: {
      type: String,
      required: true,
    },
  },
  methods: {},
  created() {

    fb.db.doc(this.itemId).get().then((doc)=>{
      let place = doc.data()
      place.id = doc.id
      place.ref = doc.ref
      this.place = place
    })
  },
};
</script>
